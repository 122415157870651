/* global sprite_path */

import $ from 'jquery';

const prevArrow = `<div class="arrow-slick arrow-slick-prev" id="arrow-slick-prev"><svg class="svg-left-circle-dims"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${sprite_path}#left-circle"></use></svg></div>`;
const nextArrow = `<div class="arrow-slick arrow-slick-next" id="arrow-slick-next"><svg class="svg-right-circle-dims"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="${sprite_path}#right-circle"></use></svg></div>`;

$(document).ready(function($) {

  $('.slick-slider').slick({
    arrows: true,
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 980,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 740,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  // document.getElementById('achievements-home-list-arrows').append(
  //   document.getElementById('arrow-slick-prev')
  // );

  // document.getElementById('achievements-home-list-arrows').append(
  //   document.getElementById('arrow-slick-next')
  // );

  $('[id^="galleryModal"]').on('shown.bs.modal', function () {
    $(this).find('.slick-slider-program').slick({
      prevArrow: prevArrow,
      nextArrow: nextArrow,
      arrows: true,
      centerMode: true,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: '.slick-slider-program-nav',
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 980,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 740,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    $('.slick-slider-program').addClass('open');
  });

  $('[id^="galleryModal"]').on('shown.bs.modal', function () {
    $(this).find('.slick-slider-program-nav').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '.slick-slider-program',
      dots: true,
      centerMode: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 980,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 740,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    $('.slick-slider-program-nav').addClass('open');
  });

  $('[id^="galleryplanModal"]').on('shown.bs.modal', function () {
    $(this).find('.slick-slider-plan').slick({
      prevArrow: prevArrow,
      nextArrow: nextArrow,
      arrows: true,
      centerMode: true,
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: '.slick-slider-plan-nav',
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 980,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 740,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    $('.slick-slider-plan').addClass('open');
  });

  $('[id^="galleryplanModal"]').on('shown.bs.modal', function () {
    $(this).find('.slick-slider-plan-nav').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '.slick-slider-plan',
      dots: true,
      centerMode: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 980,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 740,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
    $('.slick-slider-plan-nav').addClass('open');
  });
});
