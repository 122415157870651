// import external dependencies
import jquery from 'jquery';
global.$ = global.jQuery = jquery;

// import 'bootstrap/js/dist/modal';

import 'slick-carousel';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';

import './load';
import './scrolltop';
import './slide';
import './agencies';
