/* global mapbox_token, geojson */

import mapboxgl from 'mapbox-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';

mapboxgl.accessToken = mapbox_token;

const marker_options = {
  offset: {
    x: 0,
    y: -13,
  },
};

function setMarkers(geojson, map) {
  const coordinates = [];

  geojson.data.features.forEach(function (location) {
    location.geometry.forEach(point => {
      const el = document.createElement('div');
      el.className = 'marker';
      new mapboxgl.Marker(el, marker_options)
        .setLngLat(point.coordinates)
        .addTo(map);

      coordinates.push(point.coordinates);
    });
  });
}

if (document.getElementById('agencies-map')) {
  const map = new mapboxgl.Map({
    container: 'agencies-map', // container ID
    style: 'mapbox://styles/mapbox/streets-v11', // style URL
    center: [-0.5678536320494914, 44.85490826489278], // starting position [lng, lat]
    zoom: 5, // starting zoom
  });

  // map.scrollZoom.disable();
  map.addControl(new mapboxgl.NavigationControl());
  map.addControl(new MapboxLanguage({
    defaultLanguage: 'fr',
  }));

  map.on('load', function () {
    setMarkers(geojson, map);
  });
}
